/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';

import { flatten } from 'lodash';
import { DropdownOption } from 'components/Dropdown';
import { CalendarItem } from './calendar.d';

export const mapCalendarData = (data): CalendarItem[] => {
  return data.map(mapCalendarItem);
};

export const mapUsers = (data): DropdownOption[] => {
  const users = data
    .map(item => ({
      value: item.id,
      text: item.name ? `${item.name} (${item.email})` : item.email,
      search: item.email
    }))
    .sort((a, b) => a.text - b.text);

  return [{ value: 0, text: 'Not Assigned' }, ...users];
};

export const mapProjects = (data): DropdownOption[] => {
  return data
    .map(item => ({
      value: item.listing_id,
      text: [item.address1, item.address2].filter(Boolean).join(' ')
    }))
    .sort((a, b) => a.text - b.text);
};

export const mapDocuments = data => {
  const categories = [...new Set(data.map(item => item.doccat))];

  const docOptions = categories.map(category => ({
    label: category,
    options: data
      .filter(item => item.doccat === category)
      .map(item => ({ text: item.docname, value: item.id || item.doctype_id }))
      .sort((a, b) => a.text - b.text)
  }));

  const docOptionsFlat = flatten(
    docOptions.map(arr => [
      {
        disabled: true,
        text: arr.label,
        value: arr.label,
        search: arr.options.map(option => option.text).join(',')
      },
      ...arr.options
    ])
  );

  return docOptionsFlat;
};

export const mapCalendarItem = item => ({
  id: item.id || item.deadline_id || item.task_id,
  address: item.address,
  assignee: item.user_name,
  assigneeId: item.user_id,
  dateType: item.date_type,
  editable: Boolean(item.editable),
  projectType: item.project_type,
  propertyId: item.listing_id,
  visible: item.date_type === 'event' ? true : Boolean(item.visible || item.show_value),
  ordered: item.ordered === 1,
  start: Number(item.deadline)
    ? dayjs.unix(item.deadline - (item.date_type != 'event' ? 60 * dayjs(item.deadline).utcOffset() : 0))
    : Number(item.start)
    ? dayjs.unix(item.start)
    : null,
  end: Number(item.deadline_end)
    ? dayjs.unix(item.deadline_end)
    : Number(item.end)
    ? dayjs.unix(item.end)
    : null,
  timeZone: item.time_zone,
  title: item.title,
  location: item.location,
  status: item.status,
  docId: item.document_id,
  docName: item.docname,
  deadlineType: item.deadline_type,
  personalEvent: Boolean(item.is_personal),
  type: item.type,
  isExternal: item.is_external,
  category: item.category ? item.category : "other",
  emailTemplateId: item.email_template_id
});
