import dayjs from 'dayjs';

import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody, checkSuccess } from 'utils/requests';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import { DropdownOption } from 'components/Dropdown';
import { mapCalendarData, mapDocuments, mapProjects } from './mapping';

// get calendar data for a certain period of time
export async function getCalendarData(dates?: {
  start: number;
  end?: number;
}): Promise<CalendarDataResponse> {
  const params = dates ? { start: dates.start, end: dates.end } : undefined;
  const response = await fetchWithToken(`/calendar`, { query: urlEncodeBody(params).toString() });
  const data = await response.json();

  return {
    calendar: mapCalendarData(data.calendar),
    documents: mapDocuments(data.docTypes),
    listings: mapProjects(data.listings)
  };
}

// show/hide task
export const showHideTask = async (task: CalendarItem): Promise<void> => {
  const body = {
    task_id: task.id,
    hide: Number(task.visible)
  };

  await fetchWithToken(`api/v1/workspace/tasks/show_hide`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
};

// show/hide task
export const showHideEvent = async (event: CalendarItem): Promise<void> => {
  const body = {
    event_id: event.id,
    hide: Number(event.visible)
  };

  const response = await fetchWithToken(`api/v1/workspace/events/show_hide`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
  const data = await response.json();
  checkSuccess(data);
};

// show/hide deadline
export const showHideDeadline = async (deadline: CalendarItem): Promise<void> => {
  const body = {
    deadline_id: deadline.id,
    hide: Number(deadline.visible)
  };

  await fetchWithToken(`api/v1/workspace/deadlines/show_hide`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
};

// complete (mark as "Done") task
export const completeTask = async (task: CalendarItem): Promise<void> => {
  const body = {
    task_id: task.id,
    newstatus: task.status === 'Completed' ? 'Not Completed' : 'Completed'
  };

  const response = await fetchWithToken(`api/v1/workspace/tasks/update`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
  const data = await response.json();
  checkSuccess(data);
};

// create event
export async function createEvent(event) {
  const { start, end } = getTime({ ...event, dateType: 'event' });
  const deadline = dayjs(start).unix();
  const deadlineEnd = end ? dayjs(end).unix() : null;
  const body = {
    listing_id: event.propertyId,
    deadline,
    deadline_end: deadlineEnd,
    name: event.title,
    user_id: event.assigneeId,
    is_personal: Number(event.personalEvent)
  };

  const response = await fetchWithToken(`api/v1/workspace/events/add`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();
  checkSuccess(data);

  return {
    taskId: data.created.id,
    dateType: 'event'
  };
}

// edit event
export async function editEvent(event) {
  const { start, end } = getTime({ ...event, dateType: 'event' });
  const deadline = dayjs(start).unix();
  const deadlineEnd = end ? dayjs(end).unix() : null;
  const body = {
    event_id: event.id,
    deadline,
    deadline_end: deadlineEnd,
    name: event.title,
    user_id: event.assigneeId,
    is_personal: Number(event.personalEvent)
  };

  const response = await fetchWithToken(`api/v1/workspace/events/edit`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();
  checkSuccess(data);

  return {
    taskId: data.task_id,
    dateType: 'event'
  };
}

// create task
export async function createTask(task) {
  const deadline = dayjs(task.date).unix();
  const body = {
    listing_id: task.propertyId,
    deadline,
    name: task.title,
    document_type_id: task.docId || null,
    category: task.category || null,
    user_id: task.assigneeId
  };

  const response = await fetchWithToken(`api/v1/workspace/tasks/add`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();
  checkSuccess(data);

  return {
    taskId: data.created.id,
    dateType: 'task'
  };
}

// edit event
export async function editTask(task) {
  const deadline = dayjs(task.date).unix();
  const body = {
    task_id: task.id,
    deadline,
    name: task.title,
    user_id: task.assigneeId,
    category: task.category ?? null,
  };

  const response = await fetchWithToken(`api/v1/workspace/tasks/edit`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();
  checkSuccess(data);

  return {
    taskId: data.task_id,
    dateType: 'task'
  };
}

// add deadline
export async function createDeadline(deadline) {
  const body = {
    listing_id: deadline.propertyId,
    name: deadline.title,
    deadline: dayjs(deadline.date).unix()
  };

  const response = await fetchWithToken(`/api/v1/workspace/deadlines/add`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();
  checkSuccess(data);
}

// edit deadline
export async function editDeadline(deadline) {
  const body = {
    deadline_id: deadline.id,
    listing_id: deadline.propertyId,
    name: deadline.title,
    deadline: dayjs(deadline.date).unix()
  };

  const response = await fetchWithToken(`api/v1/workspace/deadlines/edit`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });

  const data = await response.json();
  checkSuccess(data);
}

// delete deadline
export async function deleteDeadline(deadline: CalendarItem): Promise<void> {
  const body = {
    deadline_id: deadline.id
  };

  await fetchWithToken(`api/v1/workspace/deadlines/delete`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
}

// delete event
export async function deleteEvent(event: CalendarItem): Promise<void> {
  const body = {
    event_id: event.id
  };

  await fetchWithToken(`api/v1/workspace/events/delete`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
}

// delete task
export async function deleteTask(task: CalendarItem): Promise<void> {
  const body = {
    task_id: task.id
  };

  await fetchWithToken(`api/v1/workspace/tasks/delete`, {
    method: 'POST',
    body: urlEncodeBody(body)
  });
}

const getTime = values => {
  const isEvent = values.dateType === 'event';
  const startTime = dayjs(values.start, 'hh:mm A');
  const endTime = dayjs(values.end, 'hh:mm A');
  const startHours = startTime.hour();
  const startMinutes = startTime.minute();
  const endHours = endTime.hour();
  const endMinutes = endTime.minute();
  const start = isEvent
    ? dayjs(values.date)
        .hour(startHours)
        .minute(startMinutes)
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss')
    : dayjs(values.date).format('YYYY-MM-DD');
  const end = isEvent
    ? dayjs(values.date).hour(endHours).minute(endMinutes).second(0).format('YYYY-MM-DD HH:mm:ss')
    : null;

  return { start, end };
};

type CalendarDataResponse = {
  calendar: CalendarItem[];
  documents: DropdownOption[];
  listings: DropdownOption[];
};

export type User = {
  active: 1 | 0;
  email: string;
  first_name: string;
  last_name: string;
  type_name: string;
  user_id: number;
  user_profile_img_url: string;
};
