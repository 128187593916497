/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useMemo, useRef, useState, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from '@emotion/styled';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import useLayout from 'hooks/useLayout';
import { uploadDocument, getDocumentVariablesValues } from 'api/documents';
import { InputCurrency, MaskInputController } from 'components';
import { Button, Dropdown, DropdownOption, Input, InputDate, Checkbox, Icon } from '@xchange/uikit';
import listingTypes from 'utils/listingTypes.json';
import financeTypes from 'utils/financeTypes.json';
import emHolderTypes from 'utils/emholdertypes.json';
import dayjs from 'dayjs';
import useDocumentTypes from 'hooks/useDocumentTypes';
import { REGEXP } from 'consts';
import WorkspaceContext from '../WorkspaceContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const extraFields = {
  listingDocuments: ['Listing Agreement'],
  miscellaneous: ['Miscellaneous'],
  contractAndAmendments: [
    'Contract',
    'Exhibit',
    'Rider',
    'Amendment',
    'Escrow Agreement',
    'Miscellaneous'
  ],
  EMReceipt: ['EM Receipt'],
  offer: ['Offer'],
  expenses: ['Expense']
};

const listingTypeOptions: DropdownOption[] = Object.values(listingTypes).map(value => ({
  value,
  text: value
}));

const emHolderTypeOptions: DropdownOption[] = Object.values(emHolderTypes).map(value => ({
  value,
  text: value
}));

const financeTypeOptions: DropdownOption[] = Object.values(financeTypes).map(value => ({
  value,
  text: value
}));
interface UploadFileMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  listingId: string;
  onSuccess: (id: number) => void;
  onCancel?: () => void;
  documentType?: string;
}

const formatDate = date => {
  if (dayjs(date).isValid()) return dayjs(date).unix();
};

const UploadFileMenu: React.FC<UploadFileMenuProps> = ({
  listingId,
  documentType,
  onSuccess,
  onCancel
}) => {  
  const layout = useLayout();
  const { listing, setListing } = useContext(WorkspaceContext);
  const { documentTypes, documentTypeOptions } = useDocumentTypes();
  const [documentValues, setDocumentValues] = useState<{ [key: string]: number | string; }>({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    register,
    control,
    formState: { errors },
    setValue,
    formState,
    watch,
    reset,
    handleSubmit
  } = useForm<any>({
    defaultValues: documentValues
  });
  const [file, setFile] = useState<File>();
  const [filePreviewSrc, setFilePreviewSrc] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [draft, doctype] = watch(['draft', 'doctype']);
  const activeDocType: WorkspaceDocumentType | undefined = useMemo(
    () => documentTypes.find(doc => doc.id === doctype),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doctype]
  );

  const handleFileInputChange = async e => {
    const file: File = e.target.files[0];
    if (!file) return;
    fileInputRef.current!.value = '';

    setValue('file', file.name);
    setFile(file);
    setCurrentPage(1);

    if (file?.type.includes('image') || file?.type.includes('pdf')) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setFilePreviewSrc(reader.result as string), false);
      reader.readAsDataURL(file);
    }
  };

  const submit = async values => {
    const {
      attorney_review_deadline,
      closing_date,
      expiration_date,
      publish_date,
      effective_date,
      inspection_due_deadline,
      mortgage_contingency_cleartoclose_deadline,
      ...formValues
    } = values;
    try {
      const docid = await uploadDocument({
        ...formValues,
        file: file!,
        new_doctype: documentType,
        listing: listingId,
        effective_date: formatDate(effective_date),
        attorney_review_deadline: formatDate(attorney_review_deadline),
        inspection_due_deadline: formatDate(inspection_due_deadline),
        mortgage_contingency_cleartoclose_deadline: formatDate(mortgage_contingency_cleartoclose_deadline),
        publish_date: formatDate(publish_date),
        expiration_date: formatDate(expiration_date),
        closing_date: formatDate(closing_date)
      });
      notify('Document uploaded');

      const l = {...listing, 
        seller : {name : formValues.name, email : formValues.email, phone : formValues.phone}
      };
      setListing(l);

      onSuccess(docid);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  const fetchDocumentValues = async (listingId) => {
    try {
      const data = await getDocumentVariablesValues(listingId);
      setDocumentValues(data);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  useEffect(() => {
    if (listingId) fetchDocumentValues(listingId);
  }, [listingId]);

  useEffect(() => {
    if (documentValues) reset(documentValues);
  }, [documentValues]);

  const preview = useMemo(() => {
    if (file?.type.includes('image')) {
      return <img className="preview-img" src={filePreviewSrc} alt="preview" />;
    }
    if (file?.type.includes('pdf')) {
      return (
        <>
          <div className="document-navigation">
            <Button
              simple
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(page => page - 1 || 1)}>
              <Icon name="arrow-simple" />
            </Button>
            {currentPage} / {totalPages}
            <Button
              simple
              disabled={currentPage === totalPages}
              onClick={() =>
                setCurrentPage(page => (page + 1 < totalPages ? page + 1 : totalPages))
              }>
              <Icon name="arrow-simple" />
            </Button>
          </div>
          <Document file={filePreviewSrc} onLoadSuccess={pdf => setTotalPages(pdf.numPages)}>
            <Page pageNumber={currentPage} width={458} />
          </Document>
        </>
      );
    }
    if (file)
      return (
        <p>
          Preview is not available for this
          <br />
          type of documents
        </p>
      );
    return <p>Select file to see preview</p>;
  }, [currentPage, file, filePreviewSrc, totalPages]);

  return (
    <StyledUploadFileMenu>
      <h2 className="title">Upload Document</h2>
      <div className="upload-document-columns">
        {layout !== 'mobile' && <div className="preview">{preview}</div>}
        <StyledForm onSubmit={handleSubmit(submit)}>
          <Input
            {...register('file', { required: 'Required' })}
            label="Select file"
            icon="fc-file"
            placeholder="Upload"
            onClick={() => fileInputRef.current!.click()}
            error={errors.file?.message}
            readOnly
          />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
          />
          <hr />
          <Controller
            control={control}
            rules={{ required: 'Required', validate: value => value !== 0 || 'Required' }}
            name="doctype"
            render={({ field, formState: { errors } }) => (
              <Dropdown
                options={documentTypeOptions}
                error={errors.doctype?.message}
                label="Type"
                placeholder="Select"
                search
                {...field}
              />
            )}
          />
          <Checkbox {...register('draft')} label="Draft" />
          <div className="extra-fields-wrapper">
            {extraFields.EMReceipt.includes(activeDocType?.name!) && (
              <>
                <Controller
                  control={control}
                  name="em_role_specific"
                  defaultValue={listing?.em_role_specific}
                  render={({ field }) => (
                    <Dropdown
                      options={emHolderTypeOptions}
                      error={errors.em_role_specific?.message}
                      label="Earnest Money Holder"
                      placeholder="Select"
                      search
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_1_deposited"
                  defaultValue={0}
                  render={({ field }) => (
                    <InputCurrency
                      label="Earnest Money Deposit (1st inst)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_2_deposited"
                  render={({ field }) => (
                    <InputCurrency
                      label="Earnest Money Deposit (2st inst)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_3_deposited"
                  render={({ field }) => (
                    <InputCurrency
                      label="Earnest Money Deposit (3st inst)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_4_deposited"
                  render={({ field }) => (
                    <InputCurrency
                      label="Earnest Money Deposit (4st inst)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
              </>
            )}
            {extraFields.offer.includes(activeDocType?.name!) && (
              <>
                <Controller
                  control={control}
                  name="offer_price"
                  rules={{ required: draft ? undefined : 'Required' }}
                  render={({ field }) => (
                    <InputCurrency label="Offer price ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="financials"
                  rules={{ required: draft ? undefined : 'Required' }}
                  render={({ field }) => (
                    <Dropdown
                      options={financeTypeOptions}
                      label="Finance type"
                      placeholder="Select"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="downpayment"
                  render={({ field }) => (
                    <Input label="Loan Amount (%)" placeholder="Enter percentage" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_1"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Earnest money 1st installment ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_2"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Earnest money 2nd installment ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="closing_date"
                  rules={{ required: draft ? undefined : 'Required' }}
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{ label: 'Closing Date', placeholder: 'MM/YY/DD' }}
                      {...field}
                    />
                  )}
                />

              </>
            )}
            {extraFields.expenses.includes(activeDocType?.name!) && (
              <Controller
                control={control}
                name="expenses"
                defaultValue=""
                render={({ field }) => (
                  <InputCurrency label="Expense ($)" placeholder="Enter" {...field} />
                )}
              />
            )}
            {extraFields.listingDocuments.includes(activeDocType?.name!) && (
              <>
                <Controller
                  control={control}
                  name="current_price"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Price ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="marketing_fee"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Upfront Flat Fee ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="commissions"
                  defaultValue=""
                  render={({ field }) => (
                    <Input label="Total Broker Commissions (%)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="sale_coop_commission"
                  defaultValue=""
                  render={({ field }) => (
                    <Input label="Coop Commissions (%)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="additional_commission"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Additional Commission ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="reimbursement_fee"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Early Termination Fee ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="mls_fee"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="MLS Fee ($)" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="publish_date"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{ label: 'Listing start date', placeholder: 'MM/YY/DD' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="expiration_date"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{ label: 'Expiration date', placeholder: 'MM/YY/DD' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="property_type"
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      options={listingTypeOptions}
                      label="Property type"
                      placeholder="Select"
                      search
                      {...field}
                    />
                  )}
                />
                <Input
                  {...register('name', {
                    pattern: { value: REGEXP.NAME, message: 'Invalid name' }
                  })}
                  defaultValue={listing?.seller?.name}
                  error={errors.name?.message}
                  label="Seller name"
                  placeholder="Enter"
                />
                <Input
                  {...register('email', {
                    pattern: { value: REGEXP.EMAIL, message: 'Invalid email address' }
                  })}
                  defaultValue={listing?.seller?.email}
                  error={errors.email?.message}
                  label="Seller email"
                  placeholder="Enter"
                />
                <MaskInputController
                  control={control}
                  name="phone"
                  defaultValue={listing?.seller?.phone}
                  rules={{
                    validate: value => !value?.includes('_')
                  }}
                  mask="(999) 999-9999"
                  error={errors.phone?.message}
                  label="Seller phone"
                  placeholder="Enter Phone Number"
                />
              </>
            )}
            {extraFields.miscellaneous.includes(activeDocType?.name!) &&
              activeDocType?.category === 'Listing Documents' && (
                <Controller
                  control={control}
                  name="commissions"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      label="Commissions (%)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
              )}
            {extraFields.contractAndAmendments.includes(activeDocType?.name!) && activeDocType?.category === 'Contract and Amendments' && (
              <>
                <Controller
                  control={control}
                  name="base_price"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Purchase Price" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_1"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency
                      label="Earnest Money (1st inst)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="earnest_money_2"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency
                      label="Earnest Money (2st inst)"
                      placeholder="Enter"
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="closing_date"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{ label: 'Closing Date', placeholder: 'MM/YY/DD' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="closing_cost_credits"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Closing Cost Credit" placeholder="Enter" {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="effective_date"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{ label: 'Execution Date', placeholder: 'MM/YY/DD' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="attorney_review_deadline"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{
                        label: 'Attorney Modification Deadline',
                        placeholder: 'MM/YY/DD'
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="inspection_due_deadline"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{ label: 'Inspection Deadline', placeholder: 'MM/YY/DD' }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="mortgage_contingency_cleartoclose_deadline"
                  defaultValue=""
                  render={({ field }) => (
                    <InputDate
                      inputProps={{
                        label: 'Mortgage Contingency Deadline',
                        placeholder: 'MM/YY/DD'
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="extras_total"
                  defaultValue=""
                  render={({ field }) => (
                    <InputCurrency label="Extras (Total)" placeholder="0.00" {...field} />
                  )}
                />
              </>
            )}
          </div>
          {layout === 'mobile' && (
            <>
              <Button secondary onClick={onCancel}>
                Cancel
              </Button>
              <Button disabled={formState.isSubmitting}>Upload</Button>
            </>
          )}
        </StyledForm>
      </div>
      {layout !== 'mobile' && (
        <div className="form-buttons">
          <Button secondary onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={formState.isSubmitting} onClick={handleSubmit(submit)}>
            Upload
          </Button>
        </div>
      )}
    </StyledUploadFileMenu>
  );
};

export default UploadFileMenu;

const StyledUploadFileMenu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin: 32px 0 16px;
  }

  .upload-document-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    height: 100%;
    margin-bottom: 70px;
    grid-gap: 32px;

    & > * {
      width: 100%;
    }
  }

  .extra-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 32px;
  }

  .preview {
    border-right: 1px solid ${props => props.theme.colors.borderColor};
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
    text-align: center;

    .document-navigation {
      .button {
        margin: 0 10px;
        padding: 3px;

        &:nth-of-type(2) .icon {
          transform: rotate(180deg);
        }
      }
    }

    .react-pdf__Document {
      width: 100%;
    }

    img {
      width: 100%;
    }

    p {
      margin-top: 20%;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    margin: 0 -20px !important;
    padding: 8px 27px;
    background: #f8f8f8;

    .button:nth-of-type(1) {
      margin-right: 16px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .extra-fields-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .upload-document-columns {
      grid-template-columns: 1fr;
      grid-gap: 32px 0;
      margin: 0;
    }
  }
`;

const StyledForm = styled.form`
  .input,
  .input-datepicker,
  .dropdown {
    width: 100%;
  }
  .overlay-date {
    width: auto;
    right: 0;
  }

  .input,
  .input-datepicker,
  .dropdown,
  .checkbox {
    margin: 8px 0;
  }

  hr {
    margin-bottom: 16px;
  }

  .button {
    width: 100%;
    margin-top: 8px;
  }
  button {
    &.prev,
    &.next {
      width: auto;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .overlay-date {
      width: 100%;
      right: 0;
    }
  }
`;
