import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import { Button, Checkbox, Input } from '@xchange/uikit';
import MaskInputController from 'components/MaskInputController';
import { REGEXP } from 'consts';
import { BuyProcessBuyerInfoData } from './types';
import { ReactComponent as BrokerSVG } from './broker.svg';
import BuyProcessHint from './BuyProcessHint';

const STEP = 2;

interface BuyProcessFormBuyerInfoProps {
  isEditing: boolean;
  onStartEdit: (step: number) => void;
  defaultValues?: Partial<BuyProcessBuyerInfoData>;
  onSubmit: (values: BuyProcessBuyerInfoData) => void;
}

const displayPhoneAndEmail = (phone, email) => {
  const hideContact = { phone, email }
  return Object.keys(hideContact).filter(key => hideContact[key]).join(' and ')
}

const BuyProcessFormBuyerInfo: React.FC<BuyProcessFormBuyerInfoProps> = ({
  isEditing,
  onStartEdit,
  defaultValues,
  onSubmit
}) => {
  const [showCobuyerFields, setShowCobuyerFields] = useState(Boolean(defaultValues?.Buyer2_name));
  const [showCompanyFields, setShowCompanyFields] = useState(Boolean(defaultValues?.company_name));
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({ defaultValues: { ...defaultValues, Buyer_phone: '' } });

  /* defaultValues is cached within the custom hook, if you want to reset defaultValues please use reset api. */
  useEffect(() => {
    reset(defaultValues);
    setShowCobuyerFields(Boolean(defaultValues?.Buyer2_name || defaultValues?.Buyer2_email));
    setShowCompanyFields(Boolean(defaultValues?.company_name || defaultValues?.company_email));
  }, [defaultValues, reset]);

  if (!isEditing && defaultValues) {
    return (
      <>
        {defaultValues.Buyer_email && (
          <div className="step-info">
            <div>
              <h3 className="step-info__primary">{defaultValues.Buyer_name}</h3>
              <p className="step-info__secondary">
                <span>Email Address: {defaultValues.Buyer_email}</span>
                <span style={{ margin: '0 16px' }}>
                  Primary phone number: {defaultValues.Buyer_phone}
                </span>
                {(defaultValues.hideBuyer_email ||  defaultValues.hideBuyer_phone) && (
                  <span>Hide buyer's {displayPhoneAndEmail(defaultValues.hideBuyer_phone, defaultValues.hideBuyer_email)} from offer</span>
                )}
              </p>
            </div>
            <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
              Edit
            </Button>
          </div>
        )}
        {defaultValues.Buyer2_email && (
          <div className="step-info">
            <div>
              <h3 className="step-info__primary">{defaultValues.Buyer2_name}</h3>
              <p className="step-info__secondary">
                <span>Email Address: {defaultValues.Buyer2_email}</span>
                <span style={{ margin: '0 16px' }}>
                  Primary phone number: {defaultValues.Buyer2_phone}
                </span>
                {(defaultValues.hideBuyer2_email ||  defaultValues.hideBuyer2_phone) && (
                  <span>Hide co-buyer's {displayPhoneAndEmail(defaultValues.hideBuyer2_phone, defaultValues.hideBuyer2_email)} from offer</span>
                )}
              </p>
            </div>
            <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
              Edit
            </Button>
          </div>
        )}
        {defaultValues.company_email && (
          <div className="step-info">
            <div>
              <h3 className="step-info__primary">{defaultValues.company_name}</h3>
              <p className="step-info__secondary">
                <span>Email Address: {defaultValues.company_email}</span>
                <span style={{ margin: '0 16px' }}>
                  Primary phone number: {defaultValues.company_phone}
                </span>
                {(defaultValues.hideCompany_email ||  defaultValues.hideCompany_phone) && (
                  <span>Hide company {displayPhoneAndEmail(defaultValues.hideCompany_phone, defaultValues.hideCompany_email)} from offer</span>
                )}
              </p>
            </div>
            <Button className="step-info__edit-btn" simple onClick={() => onStartEdit(STEP)}>
              Edit
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <BuyProcessHint>
        <BrokerSVG /> Enter buyer information
      </BuyProcessHint>
      <div className="input-grid">
        <div className="input-grid__row">
          <Input
            {...register('Buyer_name', {
              required: 'Required',
              pattern: { value: REGEXP.NAME, message: 'Invalid name' }
            })}
            name="Buyer_name"
            error={errors.Buyer_name?.message}
            label="Buyer full name*"
            placeholder="Enter name"
          />
        </div>
        <div className="input-grid__row">
          <Input
            {...register('Buyer_contact_person', {
              pattern: { value: REGEXP.NAME, message: 'Invalid name' }
            })}
            name="Buyer_contact_person"
            error={errors.Buyer_contact_person?.message}
            label="Buyer Contact Person (when company)"
            placeholder="Enter name"
          />
        </div>
        <div className="input-grid__row">
          <Input
            {...register('Buyer_email', {
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            name="Buyer_email"
            error={errors.Buyer_email?.message}
            label="Buyer email address*"
            placeholder="Enter email"
          />
          <Checkbox
            {...register('hideBuyer_email')}
            label="Hide buyer email address"
          />
        </div>
        <div className="input-grid__row">
          <MaskInputController
            control={control}
            name="Buyer_phone"
            defaultValue=""
            rules={{
              required: 'Required',
              validate: value => !value?.includes('_') || 'Invalid phone format'
            }}
            mask="(999) 999-9999"
            error={errors.Buyer_phone?.message}
            label="Buyer primary phone number*"
            placeholder="Enter phone number"
          />
          <Checkbox
            {...register('hideBuyer_phone')}
            label="Hide buyer primary phone number"
          />
        </div>
      </div>

      <div>
        <Button
          className="btn-add"
          simple
          type="button"
          onClick={() => setShowCobuyerFields(v => !v)}>
          {showCobuyerFields ? '-' : '+'} Add a co-buyer
        </Button>

        {showCobuyerFields && (
          <div className="input-grid">
            <div className="input-grid__row half-width">
              <Input
                {...register('Buyer2_name', {
                  pattern: { value: REGEXP.NAME, message: 'Invalid name' }
                })}
                defaultValue=""
                error={errors.Buyer2_name?.message}
                label="Co-Buyer full name"
                placeholder="Enter name"
              />
            </div>
            <div className="input-grid__row">
              <Input
                {...register('Buyer2_email', {
                  pattern: { value: REGEXP.EMAIL, message: 'Invalid email address' }
                })}
                defaultValue=""
                error={errors.Buyer2_email?.message}
                label="Co-Buyer email address"
                placeholder="Enter email"
              />
              <Checkbox
                {...register('hideBuyer2_email')}
                label="Hide co-buyer email address"
              />
            </div>
            <div className="input-grid__row">
              <MaskInputController
                control={control}
                name="Buyer2_phone"
                defaultValue=""
                mask="(999) 999-9999"
                error={errors.Buyer2_phone?.message}
                label="Co-Buyer primary phone number"
                placeholder="Enter phone number"
              />
              <Checkbox
                {...register('hideBuyer2_phone')}
                label="Hide co-buyer primary phone number"
              />
            </div>
          </div>
        )}

        <hr />

        {showCompanyFields && (
          <div className="input-grid">
            <div className="input-grid__row half-width">
              <Input
                {...register('company_name')}
                defaultValue=""
                error={errors.company_name?.message}
                label="Company name"
                placeholder="Enter name"
              />
            </div>
            <div className="input-grid__row">
              <Input
                {...register('company_email', {
                  pattern: {
                    value: REGEXP.EMAIL,
                    message: 'Invalid email address'
                  }
                })}
                defaultValue=""
                error={errors.company_email?.message}
                label="Company email address"
                placeholder="Enter email"
              />
              <Checkbox
                  {...register('hideCompany_email')}
                  label="Hide company email address"
                />
            </div>
            <div className="input-grid__row">
              <MaskInputController
                control={control}
                name="company_phone"
                defaultValue=""
                mask="(999) 999-9999"
                error={errors.company_phone?.message}
                label="Company phone number"
                placeholder="Enter phone number"
              />
              <Checkbox
                  {...register('hideCompany_phone')}
                  label="Hide company phone number"
              />
            </div>
          </div>
        )}
      </div>

      <Button className="submit-button">Next</Button>
    </StyledForm>
  );
};

export default BuyProcessFormBuyerInfo;

const StyledForm = styled.form`
  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px 24px;

    &__row {
      margin-bottom: 10px;
    }

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      .half-width {
        grid-column: span 2;
        .input {
          width: calc(50% - 12px);
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
    }

    .input {
      width: 100%;
      margin: 0;
    }

    .checkbox {
      margin-top: 8px;
    }
  }

  .btn-add {
    display: block;
    color: ${props => props.theme.colors.red};
    margin: 16px 0;
  }

  hr {
    width: 100%;
    border-style: dashed;
    border-color: ${props => props.theme.colors.borderColor};
    margin: 16px 0;
  }

  .input {
    margin: 10px 0;
  }

  .info-form__textarea {
    width: 100%;

    textarea {
      width: 100%;
      min-height: 140px;
      resize: vertical;
    }
  }
`;
